<template>
  <div class="h-100">
    <router-view />
    <color-switcher v-show="false" />
  </div>
</template>

<script>
import ColorSwitcher from "./components/Common/ColorSwitcher";
import Hotjar from "@/aimp/hotjar/Hotjar";
import HotjarEvent from "@/aimp/hotjar/HotjarEvent";
import { getDirection } from "./utils";

export default {
  components: {
    "color-switcher": ColorSwitcher,
  },
  computed: {
    Hotjar() {
      return Hotjar;
    },
    HotjarEvent() {
      return HotjarEvent;
    },
  },
  beforeMount() {
    const direction = getDirection();
    document.body.classList.add("ltr");
    document.dir = "ltr";
    document.body.classList.remove("rtl");
  },

  mounted() {
    if (
      localStorage.getItem("DisplayHotjarWorkflowSurvey") === "true" &&
      !localStorage.getItem("firstExecutionInWorkflow")
    ) {
      console.log("launch event");
      // settimeout to wait for the page to load
      setTimeout(() => {
        Hotjar.track(HotjarEvent.Feature.QuitWorkflowWithoutLaunchingExecution());
        console.log("event launched");
      }, 1000);
    }
  },
};
</script>

<style>
#driver-highlighted-element-stage {
  display: none !important;
}

#driver-popover-item > .driver-popover-title {
  font: bold 1.5rem "Montserrat", sans-serif !important;
}
#driver-popover-item > .driver-popover-description {
  font: 1rem "Montserrat", sans-serif !important;
}
#driver-popover-item > .driver-popover-title {
  font: bold 1.5rem "Montserrat", sans-serif !important;
}
.driver-close-btn,
.driver-prev-btn,
.driver-next-btn {
  font: 0.8rem "Montserrat", sans-serif !important;
}
</style>
