import store from ".";
import axios from "axios";

store.subscribe((mutation) => {
  switch (mutation.type) {
    case "auth/SET_ACCESS":
      if (mutation.payload) {
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${mutation.payload}`;
        localStorage.setItem("accessToken", mutation.payload);
      } else {
        axios.defaults.headers.common["Authorization"] = null;
        localStorage.removeItem("accessToken");
      }

    case "auth/SET_REFRESH":
      if (mutation.payload) {
        localStorage.setItem("refreshToken", mutation.payload);
      } else {
        localStorage.removeItem("refreshToken");
      }

    case "auth/SET_FRONTAPIKEY":
      if (mutation.payload) {
        localStorage.setItem("frontApiKey", mutation.payload);
      } else {
        localStorage.removeItem("frontApiKey");
      }

    case "auth/SET_APIKEY":
      if (mutation.payload) {
        localStorage.setItem("apiKey", mutation.payload);
      } else {
        localStorage.removeItem("apiKey");
      }

    case "auth/SET_SANDBOX_APIKEY":
      if (mutation.payload) {
        localStorage.setItem("sandboxApiKey", mutation.payload);
      } else {
        localStorage.removeItem("sandboxApiKey");
      }

      break;
  }
});
