import Vue from "vue";
import App from "./App";

// BootstrapVue add
import BootstrapVue from "bootstrap-vue";
// Router & Store add
import router from "./router";
import store from "./store";
// Multi Language Add
import en from "./locales/en.json";
import es from "./locales/es.json";
import VueI18n from "vue-i18n";
import { firebaseConfig } from "./constants/config";
// Notification Component Add
import Notifications from "./components/Common/Notification";
// Breadcrumb Component Add
import Breadcrumb from "./components/Common/Breadcrumb";
// RefreshButton Component Add
import RefreshButton from "./components/Common/RefreshButton";
// Colxx Component Add
import Colxx from "./components/Common/Colxx";
// Perfect Scrollbar Add
import vuePerfectScrollbar from "vue-perfect-scrollbar";
import contentmenu from "v-contextmenu";
import VueLineClamp from "vue-line-clamp";
import VueScrollTo from "vue-scrollto";
import firebase from "firebase/app";
import "firebase/auth";
import { getCurrentLanguage } from "./utils";
// Vue-Clipboard library
loadSegment();

import Clipboard from "v-clipboard";
Vue.use(Clipboard);
//V Click Outside
import vClickOutside from "v-click-outside";
Vue.use(vClickOutside);
//Animated text
import VueTypedJs from "vue-typed-js";
Vue.use(VueTypedJs);
//Lottie
import LottieVuePlayer from "@lottiefiles/vue-lottie-player";
Vue.use(LottieVuePlayer);

// fonts
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faSync } from "@fortawesome/free-solid-svg-icons";

import { faTwitter } from "@fortawesome/free-brands-svg-icons";

// JSON EDITOR
import VJsoneditor from "v-jsoneditor";

Vue.use(VJsoneditor);
//JSON VIEWER
import JsonViewer from "vue-json-viewer";
Vue.use(JsonViewer);

// google sign in
import GSignInButton from "vue-google-signin-button";
Vue.use(GSignInButton);

import { loadSegment, trackPage } from "./aimp/segment/segment";

Vue.use(BootstrapVue);
Vue.use(VueI18n);
const messages = { en: en, es: es };
const locale = getCurrentLanguage();
const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: "en",
  messages,
});
Vue.use(Notifications);
Vue.use(require("vue-shortkey"));
Vue.use(contentmenu);
Vue.use(VueScrollTo);
Vue.use(VueLineClamp, {
  importCss: true,
});

Vue.component("piaf-breadcrumb", Breadcrumb);
Vue.component("b-refresh-button", RefreshButton);
Vue.component("b-colxx", Colxx);
Vue.component("vue-perfect-scrollbar", vuePerfectScrollbar);

// fonts
library.add(faTwitter, fas, faSync);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

firebase.initializeApp(firebaseConfig);
Vue.config.productionTip = false;

// get current user
require("./store/subscriber");
store.dispatch("auth/attempt", {
  access: localStorage.getItem("accessToken"),
  refresh: localStorage.getItem("refreshToken"),
});

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

if (process.env.VUE_APP_SENTRY_DSN) {
  const hostname = new URL(process.env.VUE_APP_BACK_SERVER_URL).hostname;
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.CIRCLE_BRANCH,
    server_name: hostname,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.1,
  });
}

if (process.env.VUE_APP_BACK_SERVER_URL === "https://api.edenai.run/v2/") {
  console.log = () => {};
}

export default new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
  mounted() {
    trackPage();
    this.$router.afterEach((to, from) => {
      trackPage();
    });
  },
}).$mount("#app");
