/**
 * @typedef {Object} AuthuserType
 * @property {string} email
 * @property {string} fullname
 * @property {number} plan
 * @property {string} popup_programming_language
 * @property {string} popup_project_type
 * @property {number} total_credits_bought
 */

/**
 * @desc Hotjar class to handle Hotjar tracking
 * @property {() => void} init - Initialize Hotjar
 * @property {(AuthuserType) => void} identify - Identify the user to Hotjar
 * @property {(string) => void} track - Track an event in Hotjar
 **/
class Hotjar {
  static init() {
    let Hotjar = document.createElement("script");
    Hotjar.text =
      "(function(h,o,t,j,a,r){\nh.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};\nh._hjSettings={hjid:2939448,hjsv:6};\na=o.getElementsByTagName('head')[0];\nr=o.createElement('script');r.async=1;\nr.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;\na.appendChild(r);\n})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');";
    document.head.appendChild(Hotjar);
  }

  /**
   * @desc Identify the user to Hotjar
   * @param {Object} authuser
   * @param {string} authuser.email
   * @param {string} authuser.fullname
   * @param {number} authuser.plan
   * @param {string} authuser.popup_programming_language
   * @param {string} authuser.popup_project_type
   * @param {number} authuser.total_credits_bought
   * @returns {void}
   **/
  static identify(authuser) {
    window.hj =
      window.hj ||
      function () {
        // eslint-disable-next-line no-undef
        (hj.q = hj.q || []).push(arguments);
      };
    if (window.hj) {
      window.hj("identify", authuser.email, {
        email: authuser.email,
        fullname: authuser.fullname,
        plan: authuser.plan,
        programming_language: authuser.popup_programming_language,
        project_type: authuser.popup_project_type,
        paid_user: Boolean(authuser.total_credits_bought > 0 || authuser.plan > 0),
        total_credits_bought: authuser.total_credits_bought,
      });
    }
  }

  /**
   * @desc Track an event in Hotjar
   * @param {String} event
   * @returns {void}
   **/
  static track(event) {
    window.hj =
      window.hj ||
      function () {
        // eslint-disable-next-line no-undef
        (hj.q = hj.q || []).push(arguments);
      };
    if (window.hj) {
      console.log("Hotjar event: ", event);
      window.hj("event", event);
    }
  }
}

export default Hotjar;
