/**
 * @name DashBoardEvents
 * @desc List of events to track in Hotjar
 * @type {Object}
 * @property {() => string} EnterIn
 * @property {() => string} ClickOnCreditConsumed
 * @property {() => string} ClickOnCreditLeft
 * @property {() => string} ClickOnCurrentPlan
 * @property {() => string} ClickOnCopilot
 * @property {() => string} ClickOnWorkflows
 * @property {() => string} ClickOnQuickstart
 * @property {() => string} ClickOnAITech
 * @property {() => string} ClickOnLogs
 **/
const DashBoardEvents = {
  EnterIn: () => "Dashboard - Enter in",
  ClickOnCreditConsumed: () => "Dashboard - Click on Credit Consumed",
  ClickOnCreditLeft: () => "Dashboard - Click on Credit Left",
  ClickOnCurrentPlan: () => "Dashboard - Click on Current Plan",
  ClickOnCopilot: () => "Dashboard - Click on Copilot",
  ClickOnWorkflows: () => "Dashboard - Click on Workflows",
  ClickOnQuickstart: () => "Dashboard - Click on Quickstart",
  ClickOnAITech: () => "Dashboard - Click on AI Technologies",
  ClickOnLogs: () => "Dashboard - Click on Logs",
};

/**
 * @name CopilotEvents
 * @desc List of events to track in Hotjar
 * @type {Object}
 * @property {() => string} ClickOnMainPageButton
 * @property {() => string} ClickOnSideBarButton
 * @property {() => string} ClickOnSearchBarButton
 * @property {() => string} ClickOnRelatedFeatureButton
 * @property {() => string} ClickOnSuggestedFeature
 * @property {() => string} ClickOnSuggestedLink
 * @property {() => string} ClickOnCodeSnippet
 * */
const CopilotEvents = {
  ClickOnMainPageButton: () => "Copilot - Click on Main Page Button",
  ClickOnSideBarButton: () => "Copilot - Click on Side Bar Button",
  ClickOnSearchBarButton: () => "Copilot - Click on Search Bar Button",
  ClickOnPopupButton: () => "Copilot - Click on Popup Button",
  ClickOnRelatedFeatureButton: () => "Copilot - Click on Related Feature Button",
  ClickOnSuggestedFeature: () => "Copilot - Click on Suggested Feature",
  ClickOnSuggestedLink: () => "Copilot - Click on Suggested Link",
};

/**
 * @name FeatureEvents
 * @desc List of events to track in Hotjar
 * @type {Object}
 * @property {(string) => string} ClickOnDetail
 * @property {(string) => string} ClickOnRelatedFeature
 * @property {() => string} QuitWorkflowWithoutLaunchingExecution
 */
const FeatureEvents = {
  ClickOnDetail: (featureName) => `Feature - Click on ${featureName} Detail`,
  ClickOnRelatedFeature: (featureName) => `Feature - Click on ${featureName} Related Feature`,
  QuitWorkflowWithoutLaunchingExecution: () => "Leave workflow without launching an execution",
};

/**
 * @name HotjarEvent
 * @desc List of events to track in Hotjar
 * @type {Object}
 * @property {DashBoardEvents} DashBoard
 * @property {CopilotEvents} Copilot
 * @property {FeatureEvents} Feature
 * @property {(boolean) => string} SaveHistory
 * @property {(boolean) => string} ToggleCache
 * @property {(string) => string} ChangeRegion
 **/
const HotjarEvent = {
  Dashboard: DashBoardEvents,
  Copilot: CopilotEvents,
  Feature: FeatureEvents,

  /**
   * @name SaveHistory
   * @param {boolean} val
   * @returns {string}
   **/
  SaveHistory: (val) => (val ? "Activate" : "Deactivate") + " History",

  /**
   * @name ToggleCache
   * @param {boolean} val
   * @returns {string}
   **/
  ToggleCache: (val) => (val ? "Activate" : "Deactivate") + " Cache",

  /**
   * @name ChangeRegion
   * @param {string} region
   * @returns {string}
   **/
  ChangeRegion: (region) => "Change Region to " + region,
  UseTopnavSearch: () => "Use Topnav Search",
  UseCatalogSearch: () => "Use Catalog Search",
  ClickOnCatalogFilter: () => "Click on Catalog Filter",
};

export default HotjarEvent;
